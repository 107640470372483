import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import Layout from "../components/layout"
import ProjectPreview from "../components/projects/ProjectPreview"
import SEO from "../components/seo"

const Work = () => {
  const { allSitePage } = useStaticQuery(graphql`
    query WorkQuery {
      allSitePage(
        limit: 3
        sort: { fields: context___updatedAt, order: DESC }
        filter: { isCreatedByStatefulCreatePages: { eq: false } }
      ) {
        edges {
          node {
            path
            context {
              id
              title
              roles
              rawDescription {
                children {
                  text
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title="Work"
        description="I specialize in Front end and web application development and through
          the past years, I've learned from amazing people who have raised the
          bar for me. Through these experiences, I've had the chance to create
          great products which are used by multiple people."
      />
      <div>
        <h1>Check out my work</h1>
        <p>
          I specialize in Front end and web application development and through
          the past years, I've learned from amazing people who have raised the
          bar for me. Through these experiences, I've had the chance to create
          great products which are used by multiple people.
        </p>
      </div>
      <h4 className="text-gray-800 dark:text-brand-400 text-2xl font-sans mt-8 font-bold mb-4">
        My projects
      </h4>
      <p>
        I'm always looking for new things to build and grow my skillset with.
        Take a look at my projects.
      </p>
      {allSitePage.edges.map(({ node }) => {
        return (
          <ProjectPreview
            link={node.path}
            title={node.context.title}
            description={
              node.context.rawDescription
                ? node.context.rawDescription[0].children[0].text
                : ""
            }
          ></ProjectPreview>
        )
      })}
    </Layout>
  )
}

export default Work
